import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const emptySuggestionDetails = () => {
    return (
        toast.warning("Por favor preencha todos os campos para enviar sua sugestão!", {
            autoClose: 3000,
            pauseOnHover: true,
            hideProgressBar: true,
        })
    )
  
}

export default emptySuggestionDetails;