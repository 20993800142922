import React, { useState, useEffect } from 'react';
import './styles.css';
import { Table, Input } from 'reactstrap';
import api from '../../api/config';
import allCurrencyError from '../Toasters/allCurrencyError';
import { ToastContainer } from 'react-toastify';
import swal from '@sweetalert/with-react'
import SkeletonLastUpdated from '../Skeletons/SkeletonLastUpdated/SkeletonLastUpdated';
import SkeletonTable from '../Skeletons/SkeletonTable/SkeletonTable';
import DownloadFileButton from '../DownloadFileButton';
import Description from '../Descriptions/Description';
import CountryNotAvailable from '../CountryNotAvailable/CountryNotAvailable';
import SuggestCurrencyModal from '../Modal/SuggestCurrencyModal';
import CurrencyCardList from '../CurrencyCardList';
import CurrencyCardSkeleton from '../Skeletons/CurrencyCardSkeleton';
import { Select } from 'semantic-ui-react'
import moment from 'moment';

const CurrencyTable = () => {
 <ToastContainer />
    const [ currency, setCurrency ] = useState(null);
    const [ currencyIncluding, setCurrencyIncluding ] = useState(null);
    const [ lastUpdated, showLastUpdated ] = useState('');
    const [ availableCurrencyAmount, setAvailableCurrencyAmount ] = useState(null);
    const [ filterSelected, setFilterSelected ] = useState('ascending');

    useEffect(() => {
        setTimeout( async () => {
            await api.get(`/currency/all`).then(items => {
                setCurrency(items.data);
                moment.locale('pt-br');
                const time = moment(items.data[0].updatedAt).locale('pt-br');
                setAvailableCurrencyAmount(items.data.length);
                showLastUpdated('Atualizado ' + moment(items.data[0].updatedAt).format('LT'));
            }).catch(err => {
                allCurrencyError();
            });
        }, 1000)

    }, []);

    const searchInput = (ev) => {
        const currencyToSearch = ev.target.value;

        if(currencyToSearch.length === 0) {
            api.get(`/currency/all`).then(result => {
                setCurrency(result.data);
                setCurrencyIncluding(null);
            }).catch(err => {
                console.log(err);
            });
        } else {
            api.get(`/currency/include/${currencyToSearch}`).then(result => {
                 setCurrencyIncluding(currencyToSearch);
                 setCurrency(result.data);

                //  if(result.data.length == 0){
                //     setCurrency('no');
                //     console.log('no results')
                //  }
               
            }).catch(err => {
                console.log(err);
            });
        }
    }


    const showCurrencyDetails = (code) => {

        api.get(`/currency/code/${code}`).then(result => {

            const countries_list = result.data.countries.join(', ');

            swal({
                content: (
                   <div>
                  <br/><br/>
                    <h3 id = "currencyTitlePreview"> {result.data.currency.currency} ({result.data.currency.code}) </h3>
                    <h1> {result.data.currency.symbol}{result.data.currency.value} = R$1 </h1> 
 
                    <hr/>
               
                    {result.data.countries_amount === 0 && (
                        <CountryNotAvailable/>
                    )}

                    {result.data.countries_amount > 0 && (
                        <div>
                            <p id ="countriesTitle"> {result.data.countries_amount > 1 ? `Países que utilizam como moeda principal (${result.data.countries_amount}):` : `País que utiliza como moeda principal:`}  </p> 
                            <p id = "currencyTitlePreview"> { countries_list }  </p>
                            <hr/>
                        </div>
                        
                    )}


                    </div>
                    
                )
            })
            
        }).catch(() => {
            swal({
                title: 'Oops',
                text: 'Houve um erro inesperado. Tente novamente mais tarde!',
                icon: 'error',
                button: 'Fechar'
            })
        })   
    }

    // Filter options for listing results
    const filterOptions = [
        { key: 'ascending', value: 'ascending', text: 'Alfabeticamente  (A - Z)' },
        { key: 'higher', value: 'higher', text: 'Maior valor' },
        { key: 'lower', value: 'lower', text: 'Menor valor' },
        { key: 'random', value: 'random', text: 'Aleatoriamente' }
      ]

      // Function to set the filter option currently selected
      const handleChange = async (ev, { value }) => {

      
     // Verification to only request filter list to API if a new option is selected
        if(value === filterSelected){

        } else {

            setFilterSelected(value);
            await api.get(`/currency/filter/${value}`).then(data => {

                setCurrency(data.data);
            
            }).catch(err => {
                console.log(err);
            })
        
        }
      }
      
      const FilterCurrencyByButton = () => {
      
        return <Select placeholder='Ordenar resultados' options={filterOptions} onChange = {handleChange} className = 'filterOptionDropdown' />
      }


    return (

        

        <div className = "container"> 

    <div className="row">

    <div className="col">
        <h4 id = "updatedAtField"> 
            {lastUpdated === '' && (
                <SkeletonLastUpdated/>
            )}

            {lastUpdated}
        </h4> <br/> 
    </div> 

    
    <div className="col-auto">
    <Input onKeyUp = {searchInput} className = 'searchInput' placeholder="Buscar por nome" />
    </div>

    <br/> 

  
</div> <br/> 

                

 
{ currencyIncluding == null && (
                 <div>

                <DownloadFileButton 
                 downloadFileURL = {`${process.env.REACT_APP_API_URL}/reports/generate/`}
                 fileName = 'relatorio-cotacoes.xlsx'
                 />

                 <FilterCurrencyByButton/>
                 </div>
        ) }

        { currencyIncluding !== null && currency.length >= 1 &&(
 
                <div>

                <DownloadFileButton 
                downloadFileURL = {`${process.env.REACT_APP_API_URL}/reports/generate/include/${currencyIncluding}`}
                fileName = 'relatorio-cotacoes.xlsx'
                /> 

                <FilterCurrencyByButton/>
                </div>
    
                
        )}

        {currency ? (

         <div>
            <br/>
            <CurrencyCardList data = {currency} />
         </div>

        ) : (

            [1,2,3,4,5,6,7,8].map((n) => 
            
              
              <CurrencyCardSkeleton/> 
           
        
            )
        )}


 <br/>  <br/>  <br/>  <br/> <br/>

 

            {/* <Table hover id = "currencyTable">
            <thead id = "theadTable">
            <tr>
                <th>Moeda</th>
                <th>Valor</th>
                <th>Código</th>
                <th>Símbulo</th>
            </tr>
            </thead>
            <tbody>

                {currency ? (

                    currency.map((data) => {

                        return (
                            <tr id = {data.id} key={data.id}>
                                <td  onClick = {() => {showCurrencyDetails(data.code)}} key = {data.currency} >{data.currency} </td>
                                <td  onClick = {() => {showCurrencyDetails(data.code)}} key = {data.value} >R$ {data.value}</td>
                                <td  onClick = {() => {showCurrencyDetails(data.code)}} key = {data.code} >{data.code} {<p className={`currency-flag currency-flag-${data.code.toLowerCase()}`}></p>}</td>
                                <td  onClick = {() => {showCurrencyDetails(data.code)}} key = {data.symbol} >{data.symbol}</td>
                            </tr>
                        )      
                    })

                ) : (

                     [1,2,3,4,5,6,7,8,9,10,11,12].map((n) => 
                    <tr>
                    <td> <SkeletonTable/> </td>
                    <td> <SkeletonTable/> </td>
                    <td> <SkeletonTable/> </td>
                    <td> <SkeletonTable/> </td>
                    </tr>
                    )

           
                   

                )} */}


                    {/* {!currency && [1,2,3,4,5,6,7,8,9,10,11,12].map((n) => <SpinnerArea/> )} */}
              

                

      
            {/* </tbody>
        </Table> */}


     

     
              
       

        <br/> 

        { availableCurrencyAmount !== null && (
            <Description availableCurrencyAmount = {availableCurrencyAmount} />
        )} <br/> <br/> 
    
    <div className='container'>
                <SuggestCurrencyModal/>
    </div> 
    

      </div>
    )
}

export default CurrencyTable;