import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './styles.css';

const SkeletonLastUpdated = () => {
    return (
        <div id = "skeletonLastUpdated">
            <Skeleton />
        </div>
    )
}

export default SkeletonLastUpdated;