import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const matchingCalculatorDetails = () => {
    return (
        toast.warning("Selecione cotações diferentes para converter!", {
            autoClose: 3000,
            pauseOnHover: true,
            hideProgressBar: true,
        })
    )
  
}

export default matchingCalculatorDetails;