import React from "react";
import './styles.css'

const ChangePeriodChartButton = (props ) => {

    const {...buttonProps} = props;

    return (
        <div>
            <button {...buttonProps} className = {props.selected == true ? 'changePeriodButtonSelected' : 'changePeriodButtonDisabled'}> {props.text} </button>
        </div>
    )
}

export default ChangePeriodChartButton;