import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import fileSuccessfullyDownloaded from '../Toasters/fileSuccessfullyDownloaded';
import downloadReportError from '../Toasters/downloadReportError';
import download from 'js-file-download';
import { SpinningCircles } from 'react-loading-icons'

const DownloadFileButton = (props) => {

    const [ downloadLoading, setDownloadLoading ] = useState(false);
    const downloadSheet = async () => {
        
        try {
            setDownloadLoading(true);
        
            setTimeout( async () => {
                const res = await fetch(props.downloadFileURL, {
                    method: 'GET'
                });
                const blob = await res.blob();
                download(blob, props.fileName);
                setDownloadLoading(false);
                fileSuccessfullyDownloaded();
            }, 1500)
            
        } catch {
            downloadReportError();
        }
     
    }

    return <Button color='green' disabled = {downloadLoading === true ? true : false} className = "test" onClick = {downloadSheet}>
        {downloadLoading === true ? 'Gerando relatório' : 'Baixar relatório'}
        
    </Button>
}

export default DownloadFileButton;