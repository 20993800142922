import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import api from "../../api/config";
import { Line } from 'react-chartjs-2';
import './styles.css';

const ChartCurrencyHistory = (props) => {
    const [currencyHistoryData, setCurrencyHistoryData] = useState(props.data);
    const [chartData, setChartData] = useState(null)
    let mounted = false;
   
    
    useEffect( async () => {

        await api.get(`/currency/history/${props.currencyNameSlug}/${props.period}`).then(data => {

            let returnedDates = [];

            setCurrencyHistoryData(data.data.result)

          
            mounted = true;

        let dates = Object.keys(data.data.result);
        let values = Object.values(data.data.result);

            const chartData = {
                labels: dates,
                datasets: [
                  {
                    label: props.currencyName,
                    data: values,
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderColor: "rgba(70, 204, 70, 1)"
                  }
                ]
              };

              setChartData(chartData)

        }).catch(err => {
            console.log(err);
        })

    }, [props.currencyName, props.period])

  
      
        
           return (
              <div>
                {chartData !== null && (
                    <Line data = {chartData}
                    />
                )}
              </div>
           )
        

    
    
}

export default ChartCurrencyHistory;