import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const currencyValueSuccessfullyCopied = (value, currency) => {
    toast(`Valor copiado com sucesso! (R$ ${value})`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}


export default currencyValueSuccessfullyCopied;