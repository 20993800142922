import React, {useState} from "react";
import './styles.css';
import 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const CurrencyCardSkeleton = () => {


        return (
            <div>

                <br/>
                <Skeleton className="skeletonArea"/>
           
            </div>  
        )
    

}

export default CurrencyCardSkeleton;