import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const invalidEmailSuggestion = (data) => {
  
    toast.warning(data, {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true
    });
    
}



export default invalidEmailSuggestion;