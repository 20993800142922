import React from 'react';
import { Media } from 'reactstrap';
import './styles.css';
import currencyDescription from '../../assets/currency-description.png'
import updatedDescription from '../../assets/updated-description.png';
import coinDescription from '../../assets/coin-description.png';
import { Link } from 'react-router-dom';

const linkStyle = {
    "color": "green",
    "textDecoration": "none"
}

const Description = (props) => {

    return (
        
        <div className = "container">

        <Media>
            <Media body>
            <Media heading>
               <img src = {currencyDescription} alt = 'currencyIcon'/> O que é cotação?
            </Media>
            Cotação é o valor, ou preço, estabelecido pelo mercado e aplicado a um bem ou produto, neste caso, o valor atual de uma moeda estranheira específica, sendo apta a variações com base em questões econômicas/financeiras.
            </Media>
        </Media>
           
            <br/>  <br/>  

        <Media>
            <Media body>
            <Media heading>
            <img src = {updatedDescription} alt = 'updatedIcon'/> Com que frequência são atualizadas?
            </Media>
            Os valores das moedas neste site são atualizadas todos os dias de hora em hora, levando em consideração o valor atual das moedas com base na moeda brasileira, isto é, o Real (R$).
            </Media>
        </Media>

        <br/>  <br/> 
            
            <Media>
                <Media body>
                <Media heading>
                <img src = {coinDescription} alt = "currencyAvailabilityIcon"/> Quantas moedas o site atualmente apresenta?
                </Media>
                O site atualmente apresenta <i id = "availableCurrencies">{props.availableCurrencyAmount}</i> <i id = "coins">{props.availableCurrencyAmount > 1 ? 'moedas' : 'moeda'}</i>, contendo pelo menos uma de cada continente e tendo os valores atualizados de hora em hora. Você pode sugerir uma nova cotação para ser adicionada ao site clicando no botão "Sugerir cotação" abaixo.
                </Media>
            </Media>
            </div>

        
      
    )
}

export default Description;