import React, { useState } from 'react';
import Navbar from '../Navbar';
import './styles.css';
import api from '../../api/config';
import swal from '@sweetalert/with-react'
import { Button, Checkbox, Form} from 'semantic-ui-react'
import invalidEmailSuggestion from '../Toasters/invalidEmailSuggestion';
import emptySuggestionDetails from '../Toasters/emptySuggestionDetails';
import { ToastContainer } from 'react-toastify';

const Suggestion = () => {

    const [ sign_newsletter, set_sign_newsletter ] = useState(false);

    const sendSuggestion = async (data, ev) => {
        const suggestionToSend = {
            username: data.name,
            email: data.email,
            suggestionSent: data.suggestion,
            assigned_newsletter: sign_newsletter 
        }
        await api.post('/suggestion', suggestionToSend).then(result => {
     
            
          if(result.status === 200){

            set_sign_newsletter(false);
            ev.target.reset();

            swal({
              title: "Obrigado!",
              text: "Sua sugestão foi enviada com sucesso!",
              icon: "success",
              button: "Fechar",
            });
    
           
          } 
    
        }).catch(err => {
    
          if(err.response === undefined){
            swal({
              title: 'Oops',
              text: 'Houve um erro inesperado. Tente novamente mais tarde!',
              icon: 'error',
              button: 'Fechar'
          })
          } else {
            if( err.response.status === 400) {
                invalidEmailSuggestion(err.response.data.error);
            } 
          }
          
        })
      }

     const checkData = (ev) => {
      
        const data = {
            name: ev.target.name.value,
            email: ev.target.email.value,
            suggestion: ev.target.suggestion.value
        }

        if(data.name.trim().length === 0 || data.email.trim().length === 0 || data.suggestion.trim().length === 0){
            emptySuggestionDetails();
        } else {
            sendSuggestion(data, ev);
        }
     }
    
    return (
        <div>
            <Navbar/>
            <ToastContainer/>

            <br/>  <br/>
            <div style={{ display: 'flex'}} className = "container"> <br/>

            <h2 className = "sectionTitle" > Sugerir cotação </h2>
            
            </div> <br/>

            <div className = "container">
            <h5 id = "sendSuggestionSubTitle">Teve uma ideia de cotação e gostaria de compartilhar? Envie uma sugestão!</h5>
                <div> <br/> <br/>
                    {/* <form>
                        <input type = "text" placeHolder = "Digite seu nome" className = "v"/>
                        <input type = "text" placeHolder = "Digite seu email" className = "generalInput"/>
                        <input type = "text" placeHolder = "Digite a cotação que gostaria de sugerir" className = "generalInput"/> <br/> <br/>
                        <button type = "submit" className = "sendSuggestionButton">Enviar sugestão</button>
                    </form> */}

                        <Form  onSubmit = {checkData} id = "sendSuggestionForm">
                            <div>
                                <Form.Field>
                                <label className = "defaultLabel">Digite seu nome</label>
                                <input autoComplete = {'off'} name = "name" id = "name" className = "generalInput" required />
                                </Form.Field>

                                <Form.Field>
                                <label className = "defaultLabel">Digite seu e-mail</label>
                                <input autoComplete = {'off'} name = "email" className = "generalInput" required/>
                                </Form.Field>

                                <Form.Field>
                                <label className = "defaultLabel">Digite sua sugestão</label>
                                <input autoComplete = {'off'} name = "suggestion" className = "generalInput" required />
                                </Form.Field> <br/> 

                        
                                <Form.Field className = "signNewsletterButton">
                                <Checkbox onClick = {(() => {set_sign_newsletter(!sign_newsletter)})} name = "signNewsletterCheckbox" label='Aceito receber mensagens com novidades do site' />
                                </Form.Field> <br/>
                                <Button type='submit' color='green'  className = "sendSuggestionButton">Enviar</Button>
                  
                               
                            </div>
                          
                        </Form>
                </div>

            </div>
          

        </div>
    )
}

export default Suggestion;