import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const emptyCalculatorDetails = () => {
    return (
        toast.warning("Por favor preencha todos os campos para calcular a conversão!", {
            autoClose: 3000,
            pauseOnHover: true,
            hideProgressBar: true,
        })
    )
  
}

export default emptyCalculatorDetails;