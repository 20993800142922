import React from 'react';
import {  BrowserRouter, Switch, Route} from 'react-router-dom';

import Home from '../Components/Home/Home';
import CurrencySearcher from '../Components/CurrencySearcher/CurrencySearcher';
import Calculator from '../Components/Calculator/Calculator';
import Suggestion from '../Components/Suggestion/Suggestion';
import Graphic from '../Components/Graphic';
import CancelNewsletterSubscription from '../Components/CancelNewsletterSubscription';

const Routes = () => {
    return (
        <BrowserRouter >
            <Switch>
                <Route path = "/" exact component = {Home} />
                <Route path = "/buscar" component = {CurrencySearcher} />
                <Route path = "/sugerir" component = {Suggestion} />
                <Route path = "/calculadora" component = {Calculator} />
                <Route path = "/relatorios" component = {Graphic} />
                <Route path = "/status"/>
                <Route path = "/cancelar/newsletter/:email" component = {CancelNewsletterSubscription} />
            </Switch>
        </BrowserRouter >
    )
};

export default Routes;