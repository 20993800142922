import React, { useEffect, useState } from 'react';
import api from '../../api/config';
import {Bar} from 'react-chartjs-2';
import './styles.css';
import { Select,  } from 'semantic-ui-react'
import DownloadFileButton from '../DownloadFileButton';
import YesterdayTodayTable from '../YesterdayTodayTable/YesterdayTodayTable';
import ChartCurrencyHistory from '../ChartCurrencyHistory';
import slugify from 'slugify';
import ChangePeriodChartButton from '../ChangePeriodChartButton/index'

const Chart = (props) => {
  
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [currencyName, setCurrencyName] = useState('Dólar');
  const [chartData, setChartData] = useState(undefined);
  const [extraInformation, setExtraInformation] = useState({});
  const [yesterdayAndToday, setYesterdayAndToday] = useState({ yesterday: '', today: '' });
  const [chartPeriod, setChartPeriod] = useState('1d');

  const [buttonStatus, setButtonStatus] = useState({
    ultimasHoras: true,
    umaSemana: false,
    umMes: false,
    tresMeses: false
  })

  function handleButtonStatus(valueSeleced){

    if(valueSeleced == '1d'){
      setButtonStatus({
        ultimasHoras: true,
        umaSemana: false,
        umMes: false,
        tresMeses: false
      })

      setChartPeriod(valueSeleced)
    }

    if(valueSeleced == '1s'){
      setButtonStatus({
        ultimasHoras: false,
        umaSemana: true,
        umMes: false,
        tresMeses: false
      })

      setChartPeriod(valueSeleced)
    }

    if(valueSeleced == '1m'){
      setButtonStatus({
        ultimasHoras: false,
        umaSemana: false,
        umMes: true,
        tresMeses: false
      }) 
    
      setChartPeriod(valueSeleced)
    }

    if(valueSeleced != '1d' && valueSeleced != '1s' && valueSeleced != "1m"){
      setButtonStatus({
        ultimasHoras: false,
        umaSemana: false,
        umMes: false,
        tresMeses: true
      }) 

      setChartPeriod(valueSeleced)
    }
  }

  useEffect( async () => {
    await api.get(`/today/yesterday/${selectedCurrency}`).then(result => {

   
      const data = {
        labels: [`Ontem (${result.data.yesterday.date})`, `Hoje (${result.data.today.date})`],
        datasets:[
          {
            label: [`${result.data.today.currency} (${result.data.today.code})`],
            data:[
              result.data.yesterday.value,
              result.data.today.value
            ],
            backgroundColor:[
              'rgba(50,205,50, 0.8)',
              'rgba(54, 162, 235, 0.6)'
            ]
          }
        ]
      };

      setYesterdayAndToday({
        yesterday: result.data.yesterday.value,
        today: result.data.today.value
      })

      setChartData(data);
      setCurrencyName(result.data.today.currency);

      setExtraInformation({
        difference_between: result.data.difference_between,
        increased: result.data.increased,
        checkedDates: {
          today: result.data.today.date,
          yesterday: result.data.yesterday.date
        }
      });

    }).catch(err => {
      console.log(err);
    });

  }, [selectedCurrency])

  const changeCurrency = (ev, {value}) => {
    setSelectedCurrency(value);
  }

  const changePeriod = (ev, {value}) => {
    setChartPeriod(value);
  }

  const periodButtonOptions = [
    {key: 'hoje', value: '1d', text: 'Hoje (últimas horas)'},
    {key: '1 semana', value: '1s', text: '1 semana'},
    {key: '1 mês', value: '1m', text: '1 mês'},
    {key: '3 meses', value: '13m', text: '3 meses'}
  ]
  
    return (
        <div className = "container"> <br/> <br/>
           
      
          <div className = "row">

          <div className="col">
          <h2><i className='reportTitle'>Relatório</i>  <i className='currencyName'>{currencyName}</i> </h2>  
    </div> 

            <div className="col-auto">

                {props.currencyList && (
                  <div>

                  <Select
                  onChange = {changeCurrency} 
                  options={props.currencyList}
                  placeholder = 'Selecione a cotação'
                  />
{/* 
                <Select
                  onChange = {changePeriod} 
                  options={periodButtonOptions}
                  placeholder = 'Selecione o período'
                  />
                   */}
                  </div>
                )}
                
            </div>
          </div>
     

            {/* {extraInformation.increased === true && (
               <h3> Aumentou <i className = "increasedPercentage">{extraInformation.difference_between} <i class="bi bi-arrow-up-circle-fill"></i> </i> </h3>
            )}

            {extraInformation.increased === false && (
               <h3> Diminuiu <i className = "decreasedPercentage">{extraInformation.difference_between} <i class="bi bi-arrow-down-circle-fill"></i></i> </h3>
            )}

            {extraInformation.increased === 'same' && (
               <h3>  <i className = "samePercentage">Nenhuma mudança <i class="bi bi-circle-fill"></i> </i> </h3>
            )} */}


           
          {/* {chartData && (
             <DownloadFileButton 
             downloadFileURL = {`${process.env.REACT_APP_API_URL}/reports/generate/yesterday-and-today/${selectedCurrency}`}
             fileName = {`comparacao-relatorio-${selectedCurrency.toLowerCase()}.xlsx`}
           />
          )} */}

<br/>  <br/>  

        <ChartCurrencyHistory
        currencyName = {currencyName}
        selectedCurrencyCode = {selectedCurrency}
        currencyNameSlug = {slugify(currencyName).toLowerCase()}
        period = {chartPeriod}
        />
  <br/>   <br/>  
  
  {chartData !== undefined && (
    <div className='divButton'>
    <ChangePeriodChartButton onClick={ () => handleButtonStatus('1d') } value = '1d' selected = {buttonStatus.ultimasHoras}  text = {'Últimas horas'} />   
    <ChangePeriodChartButton onClick={ () => handleButtonStatus('1s') } value = '1s' selected = {buttonStatus.umaSemana} text = {'1 semana'}/>  
    <ChangePeriodChartButton onClick={ () => handleButtonStatus('1m') } value = '1m' selected = {buttonStatus.umMes} text = {'1 mês'} />  
    <ChangePeriodChartButton onClick={ () => handleButtonStatus('13m') } value = '13m' selected = {buttonStatus.tresMeses} text = {'3 meses'} />  
    </div>
  )}

  <br/>   <br/> 
{/* 
  <YesterdayTodayTable 
              yesterday = {yesterdayAndToday.yesterday}
              today = {yesterdayAndToday.today}
              difference_between = {extraInformation.difference_between} 
              increased = {extraInformation.increased}
           />
        */}
          

          {/* {chartData && (
              <div className = "chart">
                <Bar 
                data = {chartData} 
                width={100}
                height={'30px'}
                options={{ maintainAspectRatio: true }}
                />
              </div>
            )} */}
        

        <br/>   <br/>   <br/>  
        </div>
    )
}

export default Chart;