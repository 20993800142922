import React, {useEffect, useState} from "react";
import { useParams } from "react-router";
import Navbar from '../Navbar';
import api from '../../api/config';
import './styles.css';
import letterIcon from '../../assets/envelope-fill.svg';
import unexpectedError from '../Toasters/unexpectedError';
import { ToastContainer } from "react-toastify";
import 'semantic-ui-css/semantic.min.css';

const CancelNewsletterSubscription = () => {

    const params = useParams();
    const [ userEmail, setUserEmail ] = useState();
    const [cancelSubscriptionStatus, setCancelSubscriptionStatus] = useState(false);

    useEffect(() => {
        setUserEmail(params.email);

        api.delete(`/newsletter/cancel/${params.email}`).then((result) => {

            if(result.status === 200){
                console.log('Apagado com sucesso');
                setCancelSubscriptionStatus(true);
            }
            
        }).catch(err => {

            if(err.response.status === undefined || err.response.status === null){
                console.log(err);
                unexpectedError();
            }
  
            if(err.response.status === 404){
         
                setCancelSubscriptionStatus({
                    status: false,
                    error: 'non existing e-mail on newsletter list',
                    code: 404
                })
            } 
         
        })
    }, []);

    return (
        <div >
            <Navbar/>   
            <ToastContainer/>
            <div className = "container" >

                {cancelSubscriptionStatus === true ? (
                    <div className = "generalTextArea"> <br/>  <br/>  <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>  <br/> <br/>              
                    <img height = {50} src = {letterIcon} alt = "letterIcon" id = "letterIcon" />
                    <h1>Assinatura da Newsletter cancelada com sucesso para o e-mail <i className = "userEmail">{userEmail}!</i></h1>
                    <h5>Você não receberá mais mensagens sobre as novidades do conversor de moeda.</h5>
                    </div>
                ) : (
                    <div>
                        <div className = "generalTextArea"> <br/>  <br/>  <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>          
                    <img height = {50} src = {letterIcon} alt = "letterIcon" id = "letterIcon" />
                    <h1>E-mail não encontrado na lista de newsletter, tente novamente ou <a id = "backToHomeLink" href = "/">volte para a tela de início</a>.  </h1>
                    </div>
                    </div>
                )}

                  
          
            </div>
           
        </div>
    )
}

export default CancelNewsletterSubscription;