import React from 'react';
import Navbar from '../Navbar';
import CurrencyTable from '../CurrencyTable/CurrencyTable';
import { ToastContainer } from 'react-toastify';
import './styles.css'; 
import MaintenancePage from '../MaintenancePage/index';

const Home = () => {

    return (

        
        <div>

        
            <Navbar/> <br/>
            <ToastContainer />
            
            <div className = "container"> <br/> <br/>
                 <h1 style={{'font-family': 'Roboto', 'fontSize': '30px'}}> Cotações do dia </h1> <br/>
            </div>

        
            <CurrencyTable/>

           
             <br/> <br/> <br/> 

            {/* <Description/> <br/> <br/> <br/> <br/> <br/> <br/> */}
            
            <h5 id = "developedBy"> Desenvolvido com 💚 por <a id = "githubRedirect" href = {'https://github.com/victoratavila'}>Victor Atavila</a> </h5> <br/> <br/>
        </div>
    )
}

export default Home;