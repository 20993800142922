import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const currencyNotFound = () => {
  
    toast.warning("Cotação não encontrada, tente novamente!", {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true
    });
    
}



export default currencyNotFound;