import React, {useState} from 'react';
import Navbar from '../Navbar';
import { Button } from 'reactstrap';
import api from '../../api/config';
import './styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import invalidSearch from '../Toasters/invalidSearch'; 
import searchError from '../Toasters/searchError'; 
import currencyNotFound from '../Toasters/currencyNotFound';
import { Table } from 'reactstrap';

const CurrencySearcher = () => {

  const [currency, setCurrency ] = useState([]);


  const searchCurrency = async (ev) => {
    ev.preventDefault();
    const currencyToSearch = ev.target.searchCurrencyInput.value;

    if(currencyToSearch === "" || currencyToSearch === undefined || currencyToSearch === []){

      invalidSearch();

    } else {
        await api.get(`/currency/${currencyToSearch}`).then((response) => {

          if(response.data.length === 1){
            setCurrency(response.data);

          } else {
            currencyNotFound();
          }

        }).catch(err => {
          console.log(err);
          searchError();
    
        })
    }


  }
  

    return (
        
        <div>
          <Navbar/>



           <ToastContainer />
      
          <div className = "container"> <br/> <br/>
            <h1>Buscar cotação</h1> <br/>

   
              <form  onSubmit = {searchCurrency} action = "/">
              <input autoComplete = "off" required  id = "searchCurrencyInput"  name = "searchCurrencyInput" ></input>
              <Button type = "submit" color="primary" id = "searchButton" >Buscar</Button>
              </form> <br/> <br/>

  

      <div id = "foundCurrency" className = {currency.length === 0 ? 'hidden' : ''}> 

      <Table>
      <thead>
        <tr>
          <th>Moeda</th>
          <th>Valor</th>
          <th>Código</th>
          <th>Símbulo</th>
        </tr>
      </thead>
      <tbody>


      {currency.map (currencyFound => {
          return (

            <tr key = {currencyFound.id} id = {currencyFound.id}>
            <td  key = {currencyFound.currency}> {currencyFound.currency} </td>
            <td  key = {currencyFound.value}> R$ {currencyFound.value} </td>
            <td  key = {currencyFound.code}> {currencyFound.code} </td>
            <td  key = {currencyFound.symbol}> {currencyFound.symbol} </td>
            </tr>
          
            
                )
            })} 

        

      </tbody>
    </Table>
               
              
      </div>

  </div>
  </div>
  
    )
}

export default CurrencySearcher;