import React, {useState, useEffect} from "react";
import './styles.css';
import 'reactstrap';
import { ReactComponent as YourSvg } from '../../assets/copy-svgrepo-com.svg';
import { FiCopy } from 'react-icons/fi';
import currencyValueSuccessfullyCopied from "../Toasters/currencyValueSuccessfullyCopied"
import { UncontrolledTooltip } from "reactstrap";

const CurrencyCardList = (currency) => {

    const [ copyButtonStatusHidden, setCopyButtonStatusHidden ] = useState(true);

    const copyCurrencyValue = (value, currency) => {
        navigator.clipboard.writeText(value);
        currencyValueSuccessfullyCopied(value, currency);
    }

    return currency.data.map((data) => {

        return (
            <div>
                <div className="currency-card border" onMouseOver={(() => setCopyButtonStatusHidden(false))} onMouseLeave={(() => setCopyButtonStatusHidden(true))}>
                <table>
                    <tr cellSpacing={20} >
                        <th className={`currency-flag currency-flag-${data.code.toLowerCase()} flag-area`}></th>
                        <th className="currency-name"> {data.currency} </th>
                        <th className="currency-value"> <i className="description">Valor: </i> R$ {data.value} </th>
                        <th className="currency-code"> <i className="description">Código: </i> {data.code} </th>
                        <th className="currency-symbol"> <i className="description">Símbulo: </i> {data.symbol} </th>
                        <th> <i className="currency-value-copy" id = "copyValueButton" onClick={(() => copyCurrencyValue(data.value, data.currency))} hidden = {copyButtonStatusHidden}> <FiCopy/> </i>   </th>
                        
                        <UncontrolledTooltip
                            placement="right"
                            target="copyValueButton"
                        >
                            Copiar valor
                        </UncontrolledTooltip>
                   
                    </tr>
                </table>
    
                </div>
            </div>  
        )
    })

}

export default CurrencyCardList;