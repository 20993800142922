import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const generateGraphicError = () => {
    toast.error("Houve um erro durante o carregamento, tente novamente mais tarde!", {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true

      });
}


export default generateGraphicError;