import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Chart from '../Charts';
import api from '../../api/config';
import generateGraphicError from '../Toasters/generateGraphicError';
import { ToastContainer } from 'react-toastify';

const Graphic = () => {

    const [currencyList, setCurrencyList] = useState();
    useEffect(  () => {

        const fetchCurrencies = async () => {
          await api.get('/currency/all').then(result => {

            let listOfCurrency = [];
            result.data.map(example => {
              listOfCurrency.push({value: example.code, text: example.currency});
            })
            setCurrencyList(listOfCurrency);
          }).catch(err => {
            console.log(err);
            generateGraphicError();
          })
        }
    
        fetchCurrencies();
        
      }, []);

    return (
        <div>
            <Navbar/>
            <ToastContainer />
            <Chart currencyList = {currencyList} />
        </div>
    )
}

export default Graphic;