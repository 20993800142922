import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fileSuccessfullyDownloaded = () => {
    toast.success('Arquivo baixado com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}


export default fileSuccessfullyDownloaded;